(function($) {
	var initInstance = function(options, $this) {
			var instance = $.extend({}, options);

			$.each(options, function(value) {
				var config = $this.data(value.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase());

				if(typeof(config) != 'undefined') {
					instance[value] = config;
				}
			});

			if(instance.navSelector.indexOf(' ') > -1) {
				var navs = instance.navSelector.split(' ');
				instance.navChildren = navs[0];
				instance.navSelector = navs.slice(1).join(' ');
			}
			else {
				instance.navChildren = null;
			}

			return instance;
		},
		getFullWidth = function($items) {
			return $items.outerWidth() + parseInt($items.css('margin-right')) + parseInt($items.css('margin-left'));
		},
		getItems = function($container, selector) {
			var $items = $container.find(selector);

			if(!$items.length) {
				$items = $container.siblings().find(selector);
			}

			return $items;
		},
		reorderSlides = function(index, $slides, instance, slideWidth, $this) {
			var visibleSlides = Math.floor(Math.ceil($this.width() / slideWidth) / 2);

			if(instance.loop && (index == $slides.length - (visibleSlides + 1) || index == 0)) {
				var scrollLeft = $this.scrollLeft(),
					prep = {
						next: [0, 'appendTo', -1],
						prev: [-1, 'prependTo', 1]
					}[index == 0 ? 'prev' : 'next'];

				$this.css('scroll-behavior', 'auto');
				$slides.eq([prep[0]])[prep[1]]($this);
				$slides = $this.find(instance.slides);
				index += prep[2];

				$this.get(0).scroll({top: 0, left: scrollLeft + slideWidth * prep[2]});

				$this.css('scroll-behavior', 'smooth');
			}

			return [index, $slides];
		},
		getIndex = function($items, $slides, looped) {
			var index = $items.filter(':checked').attr('data-position');
			index = $.inArray($slides.filter('[data-position="' + index + '"]').get(0), $slides.get());

			return index;
		},
		getHidden = function() {
			var hidden;

			if(typeof document.hidden !== "undefined") {
				hidden = "hidden";
			}
			else if(typeof document.msHidden !== "undefined") {
				hidden = "msHidden";
			}
			else if(typeof document.webkitHidden !== "undefined") {
				hidden = "webkitHidden";
			}

			return hidden;
		},
		getVisibilityChangeEvent = function() {
			var visibilityChange;

			if(typeof document.hidden !== "undefined") {
				visibilityChange = "visibilitychange";
			}
			else if(typeof document.msHidden !== "undefined") {
				visibilityChange = "msvisibilitychange";
			}
			else if(typeof document.webkitHidden !== "undefined") {
				visibilityChange = "webkitvisibilitychange";
			}

			return visibilityChange;
		};

	$.fn.slider = function(options) {
		var selector = this.data('selector') || '.grid';

		options = $.extend({
			slides: '.article',
			navSelector: '.article_nav',
			thumbSelector: 'input[type="radio"]',
			centerMode: false,
			loop: false,
			autoplay: false,
			delay: 10
		}, options);

		return $(this).each(function() {
			var $this = $(this),
				instance = initInstance(options, $this);

			var $slides = $this.find(instance.slides),
				$items = getItems($this, instance.thumbSelector),
				$navs = instance.navChildren == null ? $this.siblings(instance.navSelector) : $this.siblings(instance.navChildren).find(instance.navSelector),
				add = instance.centerMode ? ($this.width() - getFullWidth($slides)) / 2 : 0,
				is_scrolling = false,
				scroll_again = false,
				duplicated = false;

			if($slides.length == 1) {
				return;
			}

			if(instance.loop) {
				$slides.eq(-1).prependTo($this);

				if(Math.round($this.width() / getFullWidth($slides)) - 1 != 0) {
					$slides.eq(0).appendTo($this);
				}

				$slides = $this.find(instance.slides);
			}

			var slidesOnScreen = Math.round($this.width() / getFullWidth($slides)) - 1,
				slidesInSlider = $slides.length;

			if(slidesOnScreen+4 > slidesInSlider) {
				while(slidesOnScreen+4 > slidesInSlider) {
					$slides.each(function() {
						$(this).clone().appendTo($this);
					});
					slidesInSlider = $this.find(instance.slides).length;
				}

				$slides = $this.find(instance.slides);
				duplicated = true;
			}

			$this.get(0).scroll({top: 0, left: getFullWidth($slides) * 1 - add});

			$this.on({
				scroll: function() {
					if(!is_scrolling) {
						clearTimeout($.data(this, 'scrollTimer'));
						$.data(this, 'scrollTimer', setTimeout(function() {
							var slideWidth = getFullWidth($slides),
								add = instance.centerMode ? ($this.width() - slideWidth) / 2 : 0,
								index = Math.round(($this.scrollLeft() + add) / slideWidth),
								result;

							result = reorderSlides(index, $slides, instance, slideWidth, $this);
							index = result[0];
							$slides = result[1];

							is_scrolling = true;

							$items.filter('[data-position~="' + $slides.eq(index).attr('data-position') + '"]').prop('checked', true);
							$this.get(0).scroll({top: 0, left: slideWidth * index - add, behavior: 'smooth'});

							if(instance.centerMode) {
								$slides.removeAttr('data-active').eq(index).attr('data-active', true);
							}

							setTimeout(function() {
								var visibleSlides = Math.floor(Math.ceil($this.width() / slideWidth) / 2);

								is_scrolling = false;

								if(visibleSlides > 0) {
									result = reorderSlides(index - 1, $slides, instance, slideWidth, $this);
									index = result[0];
									$slides = result[1];
								}
							}, 100);
						}, 100));
					}
				}
			});

			if($navs.length) {
				$navs.on({
					click: function() {
						var slideWidth = getFullWidth($slides),
							direction = $(this).is(instance.navSelector + '--prev') ? -1 : 1,
							add = instance.centerMode ? ($this.width() - slideWidth) / 2 : 0,
							index = Math.round(($this.scrollLeft() + add) / slideWidth) + direction,
							result;

						result = reorderSlides(index, $slides, instance, slideWidth, $this);
						index = result[0];
						$slides = result[1];

						$items.filter('[data-position~="' + $slides.eq(index).attr('data-position') + '"]').prop('checked', true);
						$this.get(0).scroll({top: 0, left: slideWidth * index - add, behavior: 'smooth'});

						if(instance.centerMode) {
							$slides.removeAttr('data-active').eq(index).attr('data-active', true);
						}

						return false;
					}
				});
			}

			if(instance.autoplay) {
				var autoplay = function() {
						$this.data('autoplayTimeout', setTimeout(function() {
							$this.get(0).scroll({top: 0, left: $this.scrollLeft() + (getFullWidth($slides) - 2), behavior: 'smooth'});
							autoplay();
						}, instance.delay * 1000));
					},
					toggle = function(onoff) {
						if(onoff) {
							clearTimeout($this.data('autoplayTimeout'));
						}
						else {
							autoplay();
						}
					},
					events = {};

				events[getVisibilityChangeEvent()] = function() {
					toggle(document[getHidden()]);
				};
				$(document).on(events);

				$this.parent().on({
					'mouseout mouseover': function(event) {
						toggle(event.type == 'mouseover');
					}
				}).trigger('mouseout');
			}

			if($items.length) {
				// connect nav with slides or it won't work
				$slides.each(function(i) {
					$(this).attr('data-position', instance.loop ? (i > 0 ? i - 1 : $slides.length - 1) : i);
					$items.eq(i).attr('data-position', i);

					/*
					if(duplicated) {
						$items.eq(i % 2).attr('data-position', [$items.eq(i % 2).attr('data-position'), i.toFixed()].join(' ').trim());
					}
					else {
						$items.eq(i).attr('data-position', i);
					}
					*/
				});

				$items.on({
					change: function() {
						var $slides = $this.find(instance.slides),
							slideWidth = getFullWidth($slides),
							$items = getItems($this, instance.thumbSelector),
							index = getIndex($items, $slides, instance.loop),
							add = instance.centerMode ? ($this.width() - getFullWidth($slides)) / 2 : 0;

						$this.get(0).scroll({top: 0, left: slideWidth * index - add, behavior: 'smooth'});
					}
				});
				$items.eq(0).prop('checked', true).change();
			}

			$(window).resize(function() {
				clearTimeout($.data(this, 'resizeTimer'));
				$.data(this, 'resizeTimer', setTimeout(function() {
					$this.scroll();
				}, 100));
			})
		});
	};

	$(function() {
		$('[data-slider]').slider();
		setTimeout(function() {
			//$('[data-slider]').scroll();
		}, 100);
	});
})(jQuery);