jQuery(function($) {
	var $html = $('html').removeClass('no-js');

	$html.deepLinks();
	
	if(!$.supports.touch) {
		$('select[multiple]').multiselect();
	}

	// ADD ALL YOUR CUSTOM JS THINGS AFTER THIS LINE
});