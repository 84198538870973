if (typeof Object.assign != 'function') {
	// Must be writable: true, enumerable: false, configurable: true
	Object.defineProperty(Object, "assign", {
		value: function assign(target, varArgs) { // .length of function is 2
			'use strict';
			if(target == null) { // TypeError if undefined or null
				throw new TypeError('Cannot convert undefined or null to object');
			}

			var to = Object(target);

			for(var index = 1; index < arguments.length; index++) {
				var nextSource = arguments[index];

				if(nextSource != null) { // Skip over if undefined or null
					for(var nextKey in nextSource) {
						// Avoid bugs when hasOwnProperty is shadowed
						if(Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
							to[nextKey] = nextSource[nextKey];
						}
					}
				}
			}
			return to;
		},
		writable: true,
		configurable: true
	});
}

(function objectfitPolyfill(f){ /in/.test(document.readyState)?setTimeout(objectfitPolyfill,5,f) : f()})(function() {
	// Detect objectFit support
	if('objectFit' in document.documentElement.style === false) {
		// assign HTMLCollection with parents of images with objectFit to variable
		var container = document.querySelectorAll('[data-objectfit]');

		// Loop through HTMLCollection
		for(var i = 0; i < container.length; i++) {
			var img = container[i].querySelector('img');

			if(!img) {
				continue;
			}

			// Asign image source to variable
			var imageSource = img.src;

			// Replace image with svg
			img.src = 'data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'' + img.width + '\' height=\'' + img.height + '\'%3E%3C/svg%3E';

			// add background settings
			Object.assign(img.style, {
				backgroundSize: img.getAttribute('data-objectfit') || 'cover',
				backgroundImage: 'url(' + imageSource + ')',
				backgroundPosition: 'center center',
				backgroundRepeat: 'no-repeat'
			});
		}
	}
});